import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import RegenerativePledge from "../components/common/regenerativePledge";
import Button from "../components/common/buttons/Button";

const NotFound = ({ data: { sanityContactPage, sanitySitesettings } }) => {
  let hero;
  let breadcrumb;
  let regenerative;

  sanityContactPage?.pageBuilder?.forEach((element) => {
    if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    }
  });
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found</title>
      </Helmet>
      <>
        <Hero
          heroSection={hero}
          h2Size="text-[50px] sm:text-[80px]"
          h2P="pb-7"
          notFound={true}
        />
        <Logo logo={sanitySitesettings?.logo} />
      </>

      {sanityContactPage?.contacts && (
        <div className="tb-container mx-auto  py-5 px-[10px] mb-2">
          {sanityContactPage?.contacts.map((item, index) => (
            <div className="flex justify-center items-center show">
              <div className="w-[300px] md:w-[560px]">
                <Button
                  key={index}
                  btnText={item?.title}
                  btnLink={item?.link}
                  btnType={item?.variant}
                  linkType={item?.type}
                  formId={item?.formId}
                  height="h-[50px]"
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings?.logo} />
        </>
      )}
    </Layout>
  );
};

export default NotFound;

export const query = graphql`
  query {
    sanityContactPage {
      pageBuilder {
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityHeroSection {
          _type
          alignment
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }

      contacts {
        title
        link
        type
        variant
        formId
        downloadLink
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
